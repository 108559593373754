<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <Form :options="formOptions" :searchData="searchData"></Form>
              <Table
                responsive
                :items="items"
                :fields="fields"
                url="ledgerreport"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios'
import Table from '@/components/Table.vue'
import Form from '@/components/form/Form.vue'
import moment from 'moment'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  components: {
    Form,
    Table
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      fields: [
        { key: 'id', label: 'S.R. No' },
        { key: 'type', label: 'Type' },
        { key: 'title', label: 'Title' },
        { key: 'accountgroup', label: 'Account Group' },
        { key: 'ledgername', label: 'Ledger Name' },
        { key: 'mobile', label: 'Mobile' },
        { key: 'email', label: 'Email' },
        { key: 'country', label: 'Country' },
        { key: 'activeinactive', label: 'Active / Inactive' },
        { key: 'coupon', label: 'Coupon' }
      ],
      items: [],
      formOptions: {
        formTitle: 'Ledger Report',
        submitRouterPath: '/reports/ledger/ledgerreoprt',
        method: 'post',
        action: 'add',
        tableForm: true,
        url: `${baseApi}/ledgerreport`,
        inputFields: [
          // {
          //   name: "branch",
          //   value: 1,
          //   type: "hidden",
          // },
          {
            label: 'Enter By',
            name: 'enterby',
            value: '',
            type: 'text',
            placeholder: 'Enter By',
            class: 'col-md-4'
          },
          {
            label: 'From Date',
            name: 'fromdate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'From Date',
            class: 'col-md-4'
          },
          {
            label: 'To Date',
            name: 'todate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            class: 'col-md-4'
          }
        ]
      }
    }
  },
  beforeMount () {
    this.getDropdowns()
  },
  methods: {
    async getDropdowns () {
      const accessToken = localStorage.getItem('accessToken')
      const baseApi = process.env.VUE_APP_APIENDPOINT
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)

              // this.form[item.name] = data[item.name];
              // if (item.name == item.dropdownname) {
              //   item.options.map((user) => {
              //     if (user.id == this.form[item.dropdownname]) {
              //       item.item = user.name;
              //     }
              //   });
              // }
            })
            .catch((error) => console.log(error, 'error'))
        }
      })
    },
    searchData (tableData) {
      this.items = tableData
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
